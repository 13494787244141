import React, { useEffect, useRef } from "react";
import "./Supporters.css";
import devfolioLogo from "../img/sponsors/devfolio.png";
import ethIndiaLogo from "../img/sponsors/ethindia-light.png";
import polygonLogo from "../img/sponsors/polygon-light.png";
import interviewcake from "../img/sponsors/Interview Cake.png";
import LiquidMind from "../img/sponsors/liquind.png";
import AoPSLogo from "../img/sponsors/AoPS.png";
import ieeeLogo from "../img/sponsors/ieee.png";
import pelsLogo from "../img/sponsors/pels.png";
import gmcLogo from "../img/sponsors/GMC.png";
import nordVPN from "../img/sponsors/NordVPN.png";
import nordPass from "../img/sponsors/NordPass.png";
import incogni from "../img/sponsors/Incogni.png";
import saily from "../img/sponsors/saily.png";
import xyzLogo from "../img/sponsors/xyz-logo-white.svg";
import xDay from "../img/sponsors/0x.Day.png";
import kwantumG from "../img/sponsors/kwantumg.png";
import elmeasureLogo from "../img/sponsors/Elmeasure.png";
import ITCLogo from "../img/sponsors/ITC.png";
import SunfeastLogo from "../img/sponsors/Sunfeast.png";
import WomaniumLogo from "../img/sponsors/WomaniumLogo.png";
import mCoretaLogo from "../img/sponsors/MCoreta.png"

const Supporters = () => {
  const supportersRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in-visible");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.2 } // Trigger when 20% of the element is in view
    );

    const elements = supportersRef.current.querySelectorAll(".fade-in-element");
    elements.forEach((el) => observer.observe(el));

    return () => observer.disconnect(); // Cleanup on component unmount
  }, []);

  return (
    <section className="supporters-section" id="supporters" ref={supportersRef}>
      <div className="support-block">
        <div className="support-heading fade-in-element">Sponsors</div>
        <div className="support-content">
          <div className="support-tier fade-in-element">
            <h2>Platinum</h2>
            <div className="plat-supporters">
              <a
                href="https://www.ieee-ies.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ieeeLogo} alt="IEEE" />
              </a>
              <a
                href="https://www.ieee-pels.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={pelsLogo} alt="PELS" />
              </a>
              <a
                href="https://quantamaze-2.devfolio.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="devfolio-logo">
                  <img src={devfolioLogo} alt="DEVFOLIO LOGO" />
                </div>
              </a>
              <a
                href="https://www.elmeasure.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="devfolio-logo">
                  <img src={elmeasureLogo} alt="Elmeasure" />
                </div>
              </a>

              <a className="kwantumg" href="https://kwantumg.com/" target="_blank" rel="noopener noreferrer">
                <img src={kwantumG} alt="kwantumg" />
              </a>
              
            </div>
          </div>

          <div className="support-tier fade-in-element">
            <h2>Gold</h2>
            <div className="gold-supporters">
              <a className="xDay fade-in-element" href="https://0x.day/" target="_blank" rel="noopener noreferrer">
                <img src={xDay} alt="0xDay" />
              </a>
              <a className="womanium"
                href="https://www.womanium.org/" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src={WomaniumLogo} alt="Womanium" />
              </a>
              <a
                href="https://ethindia.co"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ethIndiaLogo} alt="ETHINDIA LOGO" />
              </a>
              <a
                href="https://polygon.technology"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={polygonLogo} alt="POLYGON LOGO" />
              </a>
              <a 
                href="https://nordvpn.com/hackathons" 
                target="_blank" 
                rel="noopener noreferrer"
              >
                <img src={nordVPN} alt="NordVPN" />
              </a>
              <a href="https://nordpass.com/" target="_blank" rel="noopener noreferrer">
                <img src={nordPass} alt="NordPass" />
              </a>
              <a className = "incogni" href="https://incogni.com/" target="_blank" rel="noopener noreferrer">
                <img src={incogni} alt="Incogni" />
              </a>
              <a className = "saily" href="https://saily.com/ " target="_blank" rel="noopener noreferrer">
                <img src={saily} alt="Saily" />
              </a>
            </div>
          </div>

          <div className="support-tier fade-in-element">
            <h2>Silver</h2>
            <div className="silver-supporters">
              <a className="liquidmind"
                href="https://www.liquidmind.ai/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={LiquidMind} alt="LiquidMind" />
              </a>
              <a
                href="https://mcoreta.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={mCoretaLogo} alt="mCoreta" />
              </a>
              <a
                href="https://artofproblemsolving.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={AoPSLogo} alt="AoPS" />
              </a>
              <a className="interview-cake"
                href="https://www.interviewcake.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                  <img src={interviewcake} alt="Interview Cake" />
              </a>
            </div>
          </div>

          <div className="support-tier fade-in-element">
            <h3>Snack Partner</h3>
            <div className="snack-partner">
              <a
                href="https://www.itcportal.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ITCLogo} alt="ITC logo" />
              </a>
              <a
                href="https://www.itcportal.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={SunfeastLogo} alt="Sunfeast logo" />
              </a>
            </div>
          </div>

          <div className="support-tier fade-in-element">
            <h3>Certificate Partner</h3>
            <div className="certificate-partner">
              <a
                href="https://givemycertificate.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={gmcLogo} alt="Certificate Partner Logo" />
                <p className="gmc-text">Give My Certificate</p>
              </a>
            </div>
          </div>

          <div className="support-tier fade-in-element">
            <h3>Domain Partner</h3>
            <div className="domain-partner">
              <a
                href="https://gen.xyz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={xyzLogo} alt=".xyz logo" />
              </a>
            </div>
          </div>

          <div className="support-more-holder">
            <div className="gradient-border" id="box">
              Interested in sponsoring us?
              <a href="QAM_Brochure.pdf" target="_blank">
                {" "}
                Click Here <i className="fa fa-external-link"></i>
              </a>
            </div>
          </div>
        </div>

        {/* Background glow elements */}
        <div className="glow-top-left background-glow-element"></div>
        <div className="glow-top-right background-glow-element"></div>
        <div className="glow-right background-glow-element"></div>
        <div className="right-looper background-glow-element"></div>
        <div className="bg-loop-design background-glow-element"></div>
      </div>
    </section>
  );
};

export default Supporters;
