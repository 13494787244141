// Timeline.js

import React, { useEffect, useState, useRef } from "react";
import "./Timeline.css";

const timelineData = {
  day1: [
    {
      title: "Reporting",
      description:
        "What’s up, tech pioneers? Arrive at the campus ready to dive into a day of innovation and connect with fellow participants!",
      timeFrom: "11 am",
      timeTo: "0",
    },
    {
      title: "Inauguration",
      description:
        "Let the festivities begin with a lively inauguration featuring our principal, fantastic sponsors, and insights from mentors!",
      timeFrom: "2 pm",
      timeTo: "0",
    },
    {
      title: "Hack Begins",
      description:
        "It’s time to unleash your creativity as the timer starts—get your laptops ready, find your spot, and let the hacking begin!",
      timeFrom: "4 pm",
      timeTo: "0",
    },
    {
      title: "Dinner",
      description:
        "After a day of brainstorming and coding, refuel with dinner, explore the campus, and share stories before diving back into the fun!",
      timeFrom: "8 pm",
      timeTo: "0",
    },
    {
      title: "Recreational Activities",
      description:
        "Get ready for a night of fun with an epic lineup of games and activities—unleash your inner goofball, and make unforgettable memories!",
      timeFrom: "9 pm",
      timeTo: "1 am",
    },
  ],
  day2: [
    {
      title: "Breakfast",
      description:
        "After a night of brainstorming and coding, it’s time to dive into a delicious breakfast that’ll kickstart your day!",
      timeFrom: "8 am",
      timeTo: "0",
    },
    {
      title: "Lunch",
      description:
        "After a day of creativity, it’s time to come together for lunch and power up for the exciting adventures ahead!",
      timeFrom: "2 pm",
      timeTo: "0",
    },
    {
      title: "Mentoring Session",
      description:
        "Get ready for an inspiring mentoring session where you can gain insights, ask questions, and level up your skills!",
      timeFrom: "3 pm",
      timeTo: "0",
    },
    {
      title: "Dinner",
      description:
        "After a long day of hacking and fun, it’s time to unwind and enjoy a well-deserved dinner to recharge!",
      timeFrom: "8 pm",
      timeTo: "0",
    },
    {
      title: "Recreational Activities",
      description:
        "Get set for a thrilling slate of activities that’ll keep the fun rolling!",
      timeFrom: "9 pm",
      timeTo: "1 am",
    },
  ],
  day3: [
    {
      title: "Hack Ends",
      description:
        "The hackathon wraps up with a burst of creativity and caffeine-fueled triumph!",
      timeFrom: "4 am",
      timeTo: "0",
    },
    {
      title: "Rest & Breakfast",
      description:
        "It’s time to unwind and have some fun while recharging those batteries!",
      timeFrom: "4 am",
      timeTo: "8 am",
    },
    {
      title: "Idea Presentation",
      description:
        "Get ready to showcase your creativity during the idea presentation, where you'll share your innovative concepts and dazzle everyone with your brilliance!",
      timeFrom: "9 am",
      timeTo: "0",
    },
    {
      title: "First Round",
      description:
        "Kick off the excitement with the first round of idea presentations, where you unveil your innovative concepts and set the stage for creativity!",
      timeFrom: "9 am",
      timeTo: "11 am",
    },
    {
      title: "Final Round",
      description:
        "It’s time for the epic final round of idea presentations, where the top concepts shine and creativity rules!",
      timeFrom: "11 am",
      timeTo: "1 pm",
    },
  ],
};

const Timeline = () => {
  const [selectedDay, setSelectedDay] = useState("day1");
  const [visible, setVisible] = useState(false);
  const timelineRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisible(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    const currentRef = timelineRef.current;
    if (currentRef) observer.observe(currentRef);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, []);

  const handleDayChange = (day) => {
    setSelectedDay(day);
    setVisible(false);

    // Smooth re-rendering with animation reset
    setTimeout(() => {
      setVisible(true);
      timelineRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 0);
  };

  return (
    <section className="timeline-container" id="timeline" ref={timelineRef}>
      <div className={`timeline-heading ${visible ? "fade-in" : ""}`}>
        Timeline
      </div>
      <div className="timeline-text">14th - 16th November</div>

      <div className={`timeline ${visible ? "fade-in" : ""}`}>
        <div className="outer">
          {timelineData[selectedDay].map((event, index) => (
            <div
              className={`card ${visible ? "fade-in" : ""}`}
              style={{ transitionDelay: `${index * 0.2}s` }}
              key={index}
            >
              <div className="info-holder">
                <div className="info">
                  <div className="title_and_desc">
                    <div className="title">{event.title}</div>
                    <div className="event_desc">{event.description}</div>
                  </div>
                  <div className="time-holder">
                    {event.timeTo !== "0" ? (
                      <>
                        <div className="time">{event.timeFrom}</div>
                        <div className="time-to">to</div>
                        <div className="time">{event.timeTo}</div>
                      </>
                    ) : (
                      <div className="time">{event.timeFrom}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="day-buttons">
        {["day1", "day2", "day3"].map((day) => (
          <button
            key={day}
            onClick={() => handleDayChange(day)}
            className={selectedDay === day ? "active" : ""}
          >
            {day.replace("day", "Day ")}
          </button>
        ))}
      </div>

      <div className="guide-button-holder">
        <a
          href="student_guidelines.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          Student Guidelines <i className="fa fa-external-link"></i>
        </a>
      </div>

      <div
        className={`left-timeline-design ${visible ? "grow" : ""}`}
      ></div>
      <div
        className={`right-timeline-design ${visible ? "grow" : ""}`}
      ></div>
    </section>
  );
};

export default Timeline;
