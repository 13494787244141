import React, { useEffect, useState, useRef } from 'react';
import firstprize from '../img/prize/1stprize.png';
import secondprize from '../img/prize/2ndprize.png';
import thirdprize from '../img/prize/3rdprize.png';
import './Prizes.css';

const Prizes = () => {
  const [visiblePrizes, setVisiblePrizes] = useState([false, false, false]);
  const prizeRefs = useRef([]);

  useEffect(() => {
    const refsCopy = prizeRefs.current; // Copy the ref array inside the effect.

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = refsCopy.indexOf(entry.target);
            if (index !== -1) {
              setTimeout(() => {
                setVisiblePrizes((prev) => {
                  const newVisible = [...prev];
                  newVisible[index] = true;
                  return newVisible;
                });
              }, index * 200);
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    refsCopy.forEach((ref) => {
      if (ref) { // Ensure the ref is valid before observing
        observer.observe(ref);
      }
    });

    return () => {
      refsCopy.forEach((ref) => {
        if (ref) { // Ensure the ref is valid before unobserving
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  return (
    <div className="prizes-container" id="prizes">
      <div className="prizes-left bg-design"></div>
      <div className="prizes-header">Prizes</div>
      <div className="prizes-list">
        <div
          ref={(el) => (prizeRefs.current[1] = el)} // 2nd Prize
          className={`prize-item prize-second ${visiblePrizes[1] ? 'visible' : ''}`}
        >
          <img src={secondprize} alt="2nd Prize" />
          <div className="prize-text">
            <p>Rs. 30,000/-</p>
          </div>
        </div>

        <div
          ref={(el) => (prizeRefs.current[0] = el)} // 1st Prize
          className={`prize-item prize-first ${visiblePrizes[0] ? 'visible' : ''}`}
        >
          <img src={firstprize} alt="1st Prize" />
          <div className="prize-text">
            <p>Rs. 50,000/-</p>
          </div>
        </div>

        <div
          ref={(el) => (prizeRefs.current[2] = el)} // 3rd Prize
          className={`prize-item prize-third ${visiblePrizes[2] ? 'visible' : ''}`}
        >
          <img src={thirdprize} alt="3rd Prize" />
          <div className="prize-text">
            <p>Rs. 20,000/-</p>
          </div>
        </div>
      </div>

      <div className="additional-text">
        <p>...and lots of other goodies and exciting prizes</p>
      </div>

      <div className="prizes-right bg-design"></div>
      <div className="right-glow bg-design"></div>
      <div className="center-glow bg-design"></div>
      <div className="left-glow bg-design"></div>
      <div className="left-dots bg-design"></div>
    </div>
  );
};

export default Prizes;
