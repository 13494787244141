import React, { useEffect, useRef, useState } from "react";
import "./Tracks.css";

import quantumTrackImage from "../img/tracks/quant-track.svg";
import aiTrackImage from "../img/tracks/ai-track.svg";
import blockTrackImage from "../img/tracks/block-track.svg";
import evTrackImage from "../img/tracks/electric-track.svg";
import quantumBgImage from "../img/tracks/track-q-bg.jpg";
import aiBgImage from "../img/tracks/track-ai-bg.svg";
import blockBgImage from "../img/tracks/track-w3-bg.png";
import evBgImage from "../img/tracks/track-ev-bg.png";

const Tracks = () => {
  const [visibleTracks, setVisibleTracks] = useState(new Set());
  const trackRefs = useRef([]);

  useEffect(() => {
    const refs = trackRefs.current; // Store the current refs in a local variable

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setVisibleTracks((prev) => new Set([...prev, entry.target]));
          }
        });
      },
      { threshold: 0.1 }
    );

    refs.forEach((ref) => observer.observe(ref));

    return () => {
      refs.forEach((ref) => observer.unobserve(ref)); // Use the local variable here
    };
  }, []); // Empty dependency array ensures this runs only once

  const trackData = [
    {
      title: "Quantum computing",
      content: `Dive into the cutting-edge realm of quantum computing in this hackathon.
        Harness the mind-bending concepts of qubits, superposition, and entanglement 
        to develop revolutionary algorithms and applications.`,
      backgroundImage: quantumBgImage,
      trackImage: quantumTrackImage,
      bgClassName: "track-quantum-bg",
    },
    {
      title: "Generative AI & Machine learning",
      content: `Explore the fascinating world of Generative AI & Machine Learning and 
        unleash your creativity. Dive into the potential of AI to generate innovative 
        technology solutions. Join us to push the boundaries of what's possible!`,
      backgroundImage: aiBgImage,
      trackImage: aiTrackImage,
      bgClassName: "track-aiml-bg",
    },
    {
      title: "Web3 and BlockChain",
      content: `Web3 & Blockchain delves into the exciting world of decentralized tech! 
        Discover how blockchain revolutionizes secure transactions, smart contracts, 
        and decentralized apps (dApps). Come innovate with us and shape the future!`,
      backgroundImage: blockBgImage,
      trackImage: blockTrackImage,
      bgClassName: "track-web3-bg",
    },
    {
      title: "Electric Vehicles and Future Mobility",
      content: `Join our EVs track and help shape the future of transportation! 
        Dive into creative solutions for improving electric vehicle performance, 
        charging convenience, and environmental sustainability.`,
      backgroundImage: evBgImage,
      trackImage: evTrackImage,
      bgClassName: "track-ev-bg",
    },
  ];

  return (
    <div className="tracks-container" id="tracks">
      <div className="tracks-header">Tracks</div>
      <div className="track-grid">
        {trackData.map((track, index) => (
          <div
            key={index}
            ref={(el) => (trackRefs.current[index] = el)}
            className={`track-card ${
              visibleTracks.has(trackRefs.current[index]) ? "visible" : ""
            }`}
            style={{ "--animation-delay": `${index * 0.2}s` }}
          >
            <div
              className={`track-bg ${track.bgClassName}`}
              style={{ backgroundImage: `url(${track.backgroundImage})` }}
            ></div>
            <div
              className="track-card-img"
              style={{ backgroundImage: `url(${track.trackImage})` }}
            ></div>
            <div className="track-card-content">
              <div className="track-heading">{track.title}</div>
              <div className="track-content">{track.content}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tracks;
