import React from 'react';
import { Analytics } from '@vercel/analytics/react';
import HeroWithNav from '../components/HeroWithNav';
import AboutUs from '../components/AboutUs';
import Tracks from '../components/Tracks';
import Prizes from '../components/Prizes';
import Supporters from '../components/Supporters';
import Timeline from '../components/Timeline';
import Judge from '../components/Judge_card';
import ContactUs from '../components/ContactUs';

export default function RootLayout() {
    return (
        <div className="App">
            <section className="hero-block">
                <HeroWithNav />
            </section>
            <section className="about-block" id="about-us">
                <AboutUs />
            </section>
            <section className="tracks-block" id="tracks">
                <Tracks />
            </section>
            <section className="prizes-block" id="prizes">
                <Prizes />
            </section>
            <section className="timeline-block" id="timeline">
                <Timeline />
            </section>
            <section className="judge-block" id="judge">
                <Judge/>
            </section>
            <section className="supporters-block" id="supporters">
                <Supporters />
            </section>
            <section className="contact-block" id="contact">
                <ContactUs />
            </section>

            <Analytics />
        </div>
    );
}
