import React, { useEffect, useState, useCallback, useMemo } from 'react';
import './Hero.css';
import mainLogo from '../img/home/logo.svg';

const Hero = () => {
  // Memoized hackathon date to avoid unnecessary re-renders
  const hackathonDate = useMemo(() => new Date('2024-11-14T16:00:00'), []);

  // Stable calculateTimeLeft function using useCallback
  const calculateTimeLeft = useCallback(() => {
    const now = new Date();
    const difference = hackathonDate - now;

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return { days: 0, hours: 0, minutes: 0, seconds: 0 }; // Default if the event has started or ended
  }, [hackathonDate]);

  // State to track time left
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft);

  // Load the Devfolio SDK script dynamically
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://apply.devfolio.co/v2/sdk.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Countdown timer logic using setInterval for consistent ticking
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000); // Update every second

    return () => clearInterval(timer); // Clean up the interval
  }, [calculateTimeLeft]);

  return (
    <section className="hero">
      <div className="hero-heading">
        <img src={mainLogo} alt="Quantamaze Logo" />
        <h2>A 36-Hour Hackathon</h2>
      </div>
      <div className="apply-button-container" style={{ height: '44px', width: '312px', marginTop: '1rem' }}>
        <div 
          className="apply-button" 
          data-hackathon-slug="quantamaze-2" 
          data-button-theme="dark-inverted"
          style={{ height: '44px', width: '312px' }}
        ></div>
      </div>

      <div className="hero-subheading">
        <div className="event-date">
          November 14 - 16
        </div>
        <div className="event-venue">
          Nitte Meenakshi Institute of Technology, Bangalore
        </div>

      <div className="results-announcement">
          <h3>Results are out!</h3>
          <a href="Finalists QAM 2.0.pdf" target="_blank" rel="noopener noreferrer" className="download-button">
            Shortlisted Teams
          </a>
        </div>
      </div>

      {/* Countdown Section */}
      <div className="countdown-container">
        <h3>Days remaining for hackathon</h3>
        <div className="timer-container">
          <div className="timer-box">
            <h2>{timeLeft.days}</h2>
            <span>Days</span>
          </div>
          <div className="timer-box">
            <h2>{timeLeft.hours}</h2>
            <span>Hours</span>
          </div>
          <div className="timer-box">
            <h2>{timeLeft.minutes}</h2>
            <span>Minutes</span>
          </div>
          <div className="timer-box">
            <h2>{timeLeft.seconds}</h2>
            <span>Seconds</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
