import React, { useState, useEffect } from 'react';
import './Navbar.css';

const NavbarComponent = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setIsPopupOpen(false); // Close popup after navigation
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0); // Detect scroll movement
    };

    const handleEsc = (event) => {
      if (event.key === 'Escape') setIsPopupOpen(false); // Close on Esc key
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <>
      <nav className={`navbar ${isScrolled ? 'scrolled' : ''}`}>
        <div className="logo-holder">
          <div className="qbit-logo"></div>
        </div>

        {/* Show popup menu instead of navbar links when it's open */}
        {isPopupOpen ? (
          <div className="popup-menu">
            <div className="popup-links">
              <button onClick={() => scrollToSection('hero')}>Home</button>
              <button onClick={() => scrollToSection('about-us')}>About Us</button>
              <button onClick={() => scrollToSection('tracks')}>Tracks</button>
              <button onClick={() => scrollToSection('prizes')}>Prizes</button>
              <button onClick={() => scrollToSection('timeline')}>Timeline</button>
              <button onClick={() => scrollToSection('supporters')}>Sponsors</button>
              <button onClick={() => scrollToSection('contact')}>Contact Us</button>
            </div>
          </div>
        ) : (
          <div className="navbar-links">
            <button onClick={() => scrollToSection('hero')}>Home</button>
            <button onClick={() => scrollToSection('about-us')}>About Us</button>
            <button onClick={() => scrollToSection('tracks')}>Tracks</button>
            <button onClick={() => scrollToSection('prizes')}>Prizes</button>
            <button onClick={() => scrollToSection('timeline')}>Timeline</button>
            <button onClick={() => scrollToSection('supporters')}>Sponsors</button>
            <button onClick={() => scrollToSection('contact')}>Contact Us</button>
          </div>
        )}

        <button
          className="hamburger-btn"
          onClick={() => setIsPopupOpen(!isPopupOpen)}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </button>
      </nav>
    </>
  );
};

export default NavbarComponent;
