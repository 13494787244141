import React, { useRef } from 'react';
import './Judge_card.css';
import j_arthi_udaykumar from '../img/judges/j_arthi_udaykumar.png';
import j_naveen_athresh from '../img/judges/j_naveen_athresh.webp';
import j_hima_bindu from '../img/judges/j_hima_bindu.png';
import vidhyadhiraja from '../img/judges/vidhyadiraja.jpeg';
import prashanth from '../img/judges/prashanth.jpeg';
import rameshdarla from '../img/judges/rameshdarla.jpeg';

const judges = [
    {
        photo: j_arthi_udaykumar,
        name: 'Arthi Udayakumar',
        designation: 'AI/ML Specialist',
        company: 'Accenture',
        linkedin: 'https://www.linkedin.com/in/arthi-udayakumar',
    },
    {
        photo: j_naveen_athresh,
        name: 'Naveen Athresh',
        designation: 'Founder',
        company: 'Liquidmind',
        linkedin: 'https://www.linkedin.com/in/naveen-athresh-090b0a',
    },
    {
        photo: j_hima_bindu,
        name: 'Hima Bindu',
        designation: 'Senior Manager',
        company: 'Ernst & Young',
        linkedin: 'https://www.linkedin.com/in/hima-bindu-1204/',
    },
    {
        photo: vidhyadhiraja,
        name: 'Vidhyadhiraja Sudhindra',
        designation: 'Dean',
        company: 'JNCASR',
        linkedin: 'https://www.linkedin.com/in/nsvraja',
    },
    {
        photo: prashanth,
        name: 'Prashanth BM',
        designation: 'Co - Founder',
        company: '0x.Day',
        linkedin: 'https://www.linkedin.com/in/0xprash/',
    },
    {
        photo: rameshdarla,
        name: 'Ramesh Darla',
        designation: 'Asst General Manager',
        company: 'Panasonic Life Solutions',
        linkedin: 'https://www.linkedin.com/in/darla1/',
    },
];

export const JudgeCard = () => {
    return (
        <div className="judge-section">
            <h1 className="judges-header">Judges</h1>
            <div className="judge-container">
                <div className="judge-cards-wrapper">
                    {judges.map((judge, index) => (
                        <div
                            className="judge-card"
                            key={index}
                            onClick={() => window.open(judge.linkedin, '_blank')}
                        >
                            <img
                                src={judge.photo}
                                alt={`${judge.name}'s profile`}
                                className="judge-card-photo"
                            />
                            <h2 className="judge-card-name">{judge.name}</h2>
                            <p className="judge-card-designation">{judge.designation}</p>
                            <p className="judge-card-company">{judge.company}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default JudgeCard;
