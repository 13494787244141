import React, { useEffect, useRef } from 'react';
import './ContactUs.css';

const ContactUs = () => {
  const sectionRef = useRef(null);

  const instagramLink = 'https://www.instagram.com/qbits_nmit';
  const linkedinLink = 'https://www.linkedin.com/company/qbitsnmit/';
  const emailLink = 'mailto:qbits@nmit.ac.in';
  const discordLink = 'https://www.discord.com/invite/pJB5zKGPZt';

  useEffect(() => {
    const currentSection = sectionRef.current; // Store the current ref value
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in-visible');
            observer.unobserve(entry.target); // Stop observing once visible
          }
        });
      },
      { threshold: 0.1 }
    );

    if (currentSection) {
      observer.observe(currentSection); // Observe the section
    }

    return () => {
      if (currentSection) {
        observer.unobserve(currentSection); // Cleanup
      }
    };
  }, []);

  return (
    <section ref={sectionRef} className="contact fade-in-element" id="contact">
      <div className="contact-heading">
        Contact Us
      </div>

      <div className="map-section">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3885.5216353794026!2d77.58508387507918!3d13.129462687200625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae170d10bb559b%3A0x2bb3892a626cf9ba!2sNitte%20Meenakshi%20Institute%20of%20Technology!5e0!3m2!1sen!2sin!4v1727097642107!5m2!1sen!2sin" 
          title="NMIT Location Map"
          width="400" 
          height="250" 
          style={{ border: 0 }} 
          allowFullScreen="" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade">
        </iframe>
      </div>

      <div className="travel-button-holder">
        <a
          href="Travel Options to NMIT for Outstation Students.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="travel-button"
        >
          Travel Options <i className="fa fa-external-link"></i>
        </a>
      </div>


      <div className="contact-buttons">
        <a href={instagramLink} target="_blank" rel="noopener noreferrer">
          <img src={require('../img/contact/instagram.png')} alt="Instagram" />
        </a>
        <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
          <img src={require('../img/contact/linkedin.png')} alt="Linkedin" />
        </a>
        <a href={emailLink} target="_blank" rel="noopener noreferrer">
          <img src={require('../img/contact/mail.png')} alt="Email" />
        </a>
        <a href={discordLink} target="_blank" rel="noopener noreferrer">
          <img src={require('../img/contact/discord.png')} alt="Discord" />
        </a>
      </div>
    </section>
  );
};

export default ContactUs;
