// AboutUs.js
import React, { useRef, useEffect, useState } from "react";
import "./AboutUs.css";

// Custom Hook to track section visibility using Intersection Observer
const useIntersectionObserver = (options) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.disconnect(); // Stop observing after becoming visible
      }
    }, options);

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    return () => observer.disconnect();
  }, [options]);

  return [elementRef, isVisible];
};

// AboutUsContent Component
const AboutUsContent = () => (
  <div className="about-us-content">
    <div className="about-us-main-heading">About Us</div>
    <div className="about-us-desc">
      Q-Bits is a student club that aims to bring primary quantum computing
      resources. We provide resources for students who are just beginning to
      learn about quantum computing to those who are looking for internships
      and jobs in the quantum computing area. Throughout the year, the club
      will host events that expose students to new ideas in the field of
      quantum computing, including Ted talks, blog writing, panel discussions,
      educational workshops, Hackathons, social events, but not limited to.
    </div>
  </div>
);

// AboutUsStats Component
const AboutUsStats = () => (
  <div className="about-us-stats">
    <div className="stat-item">
      <div className="stat-number">120<span className="plus">+</span></div>
      <div>&nbsp;Participants</div>
    </div>
    <div className="stat-item">
      <div className="stat-number">400<span className="plus">+</span></div>
      <div>&nbsp;Applications</div>
    </div>
    <div className="stat-item">
      <div className="stat-number">100<span className="plus">%</span></div>
      <div>&nbsp;Positive feedback</div>
    </div>
    <div className="stat-item">
      <div className="stat-number">10<span className="plus">+</span></div>
      <div>&nbsp;Industrial experts</div>
    </div>
  </div>
);

// Main AboutUs Component with Intersection Observer
const AboutUs = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.2 });

  return (
    <section
      id="about-us"
      className={`about-us-section fade-in-section ${
        isVisible ? "visible" : ""
      }`}
      ref={ref}
    >
      <div className="about-us-main">
        <div className="about-us-content-wrapper">
          <AboutUsContent />
          <AboutUsStats />
        </div>
        <div className="left-about-us-design bg-design"></div>
        <div className="right-about-us-design bg-design">
          <div className="main-circle bg-design"></div>
          <div className="bg-looper bg-design"></div>
          <div className="bg-circle bg-design"></div>
          <div className="bg-glow bg-design"></div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
